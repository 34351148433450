import { BaseExpertFragment } from '../graphql/expertList'
import { ExpertListingSectionFragment } from '../graphql/expertListingSectionFragment.generated'
import ExpertListingSectionEMN from '../sections/ExpertListingSection/ExpertListingSection.EMN'
import convertDatoImage from '../utils/convertDatoImage'
import notEmpty from '../utils/notEmpty'
import { usePageContext } from '../utils/PageContext'
import resolveUrlForRecord from '../utils/resolveUrlForRecord'

interface Props {
  content: ExpertListingSectionFragment
  data: {
    experts: BaseExpertFragment[]
    numTotalExperts: number
    currentPage: number
    disciplines: string[]
    specialisms: string[]
    nations: string[]
    expertises: string[]
  }
}

const ExpertListingSectionBlock = ({
  content: {
    results,
    previousPage,
    nextPage,
    goToPage,
    linkedInProfile: linkedInProfileLabel,
    qualifications: qualificationsLabel,
    disciplines: disciplinesLabel,
    discipline: disciplineLabel,
    specialisms: specialismsLabel,
    specialism: specialismLabel,
    moreInformation,
    filteredOn,
    removeFilters,
    noResultsFound,
    noResultsDescription,
  },
  data: { experts, numTotalExperts, currentPage, disciplines, specialisms },
}: Props) => {
  const { siteConfig } = usePageContext()
  if (
    !results ||
    !previousPage ||
    !nextPage ||
    !goToPage ||
    !linkedInProfileLabel ||
    !qualificationsLabel ||
    !disciplinesLabel ||
    !disciplineLabel ||
    !specialismsLabel ||
    !specialismLabel ||
    !moreInformation ||
    !filteredOn ||
    !removeFilters ||
    !siteConfig.expertsIndex?.slug ||
    !noResultsFound ||
    !noResultsDescription
  ) {
    return null
  }

  return (
    <ExpertListingSectionEMN
      disciplines={disciplines}
      disciplineLabel={disciplineLabel}
      specialisms={specialisms}
      specialismLabel={specialismLabel}
      experts={experts
        .map(
          ({
            slug,
            name,
            photo,
            phone,
            email,
            linkedIn,
            disciplines,
            specialisms,
            qualifications,
          }) => {
            if (!slug || !name || !photo) {
              return undefined
            }

            return {
              href: resolveUrlForRecord(siteConfig, {
                __typename: 'ExpertRecord',
                slug,
              }),
              name,
              photo: convertDatoImage(photo),
              phone: phone || undefined,
              email: email || undefined,
              linkedIn: linkedIn || undefined,
              disciplines: disciplines
                .map(({ name }) => name)
                // If a discipline doesn't have a value for the current locale,
                // name may be an empty string
                .filter((name) => name !== '')
                .filter(notEmpty),
              specialisms: specialisms
                .map(({ name }) => name)
                // If a specialism doesn't have a value for the current locale,
                // name may be an empty string
                .filter((name) => name !== '')
                .filter(notEmpty),
              qualifications: qualifications || undefined,
              linkedInProfileLabel,
              qualificationsLabel,
              disciplinesLabel,
              specialismsLabel,
              moreInformation,
            }
          },
        )
        .filter(notEmpty)}
      numTotalExperts={numTotalExperts}
      currentPage={currentPage}
      resultsLabel={results}
      previousPageLabel={previousPage}
      nextPageLabel={nextPage}
      goToPageLabel={goToPage}
      filteredOn={filteredOn}
      removeFilters={removeFilters}
      pathname={`/${siteConfig.expertsIndex.slug}`}
      noResultsFound={noResultsFound}
      noResultsDescription={noResultsDescription}
    />
  )
}

export default ExpertListingSectionBlock
