import { Theme, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactNode } from 'react'

import { currentSite, Site } from '../sites'
import emnTheme from '../themes/emn'

// eslint-disable-next-line consistent-return
export const getTheme = (site: Site): Theme => {
  // eslint-disable-next-line default-case
  switch (site) {
    case Site.EMN:
      return emnTheme
  }
}

const theme = getTheme(currentSite)

interface Props {
  children: ReactNode
  theme?: Theme
}

const ThemeProvider = ({ children, theme: customTheme }: Props) => (
  <EmotionThemeProvider theme={customTheme || theme}>
    {children}
  </EmotionThemeProvider>
)

export default ThemeProvider
